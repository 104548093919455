<template>
  <div>
    <header-view
      title="Species Admin"
      previous_page="NO_BACK_BUTTON"
      :btnOptions="[
        {
          name: 'Add Single Species',
          action: () => {
            addSingleDialog = true;
          },
        },
        {
          name: 'Add Species List',
          action: () => {
            addListDialog = true;
          },
        },
      ]" />
    <main-container>
      <add-species-list
        admin
        :addSpeciesListDialog="addListDialog"
        @list-added="loadMasterSpecies"
        @close="addListDialog = false" />
      <add-species-single
        admin
        :addSpeciesDialog="addSingleDialog"
        @species-added="loadMasterSpecies"
        :speciesList="masterList"
        @close="addSingleDialog = false" />
      <species-table admin :speciesList="masterList"></species-table>
    </main-container>
  </div>
</template>

<script>
  // components
  import SpeciesTable from '@/components/species/species-table';
  import AddSpeciesList from '@/components/species/add-species-list';
  import AddSpeciesSingle from '@/components/species/add-species-single';
  import MainContainer from '@/components/main-container';
  import HeaderView from '@/components/header-view';

  // services
  import Tenants from '@/services/Tenants.service.js';

  export default {
    name: 'AppSpeciesAdminList',
    components: {
      'species-table': SpeciesTable,
      'add-species-list': AddSpeciesList,
      'add-species-single': AddSpeciesSingle,
      'main-container': MainContainer,
      'header-view': HeaderView,
    },
    data() {
      return {
        masterList: [],
        uploadResults: [],
        statusInfo: {},
        speciesLoaded: false,
        showStatusDialog: false,
        addListDialog: false,
        addSingleDialog: false,
      };
    },

    mounted() {
      this.loadMasterSpecies();
    },
    methods: {
      //get all species in master list, verified or not
      async loadMasterSpecies() {
        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        // execute database query
        var res = await Tenants.getMasterSpecies({}, accessToken);

        if (res) {
          this.masterList = res;
        } else {
          console.log('failed to load master species');
        }
      },
    },
  };
</script>
